import React from "react"
import Layout from "../../../components/sermonLayoutElMirage"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/U9bYZewbTic">
    <SEO title="Priest, Perfection, and Position - Hebrews" />
  </Layout>
)

export default SermonPost
